<template>
  <div class="col-md-8 mx-auto">
    <div class="card rounded shadow-lg">
      <div class="card-body">
        <div class="card-title text-center">
          <h1>{{ title }}</h1>
        </div>
        <br>
        <form v-on:submit.prevent="saveItem()">
          <div class="form-row">
            <div class="form-group col-md-3">
              <label>Código (*):</label>
              <input type="text" class="form-control" v-model="item.tcr_cdgo" required autofocus>
            </div>
            <div class="form-group col-md-9">
              <label>Nombre (*):</label>
              <input type="text" class="form-control" v-model="item.tcr_nmbre" required>
            </div>
          </div>
          <div class="form-group text-center">
            <input type="checkbox" class="form-check-input" id="tcr_corr" v-model="item.tcr_corr">
            <label for="tcr_corr">Es correspondencia:</label>
          </div>
          <div class="form-group">
            <label>Tipo de respuesta:</label>
            <select v-model="item.tcr_respuesta" class="form-control">
              <option v-for="tpocor in tpocors" v-bind:value="tpocor._id">
                {{ tpocor.tcr_nmbre }}
              </option>            
            </select>
            <button type="text" class="btn btn-secondary" v-on:click.prevent="eliminarTpocor"><i class="fa fa-eraser"></i> Borrar</button>
          </div>
          <div class="form-group">
            <label>Dirección:</label>
            <select v-model="item.tcr_direccion" class="form-control">
              <option value="R">Recibida</option>
              <option value="E">Enviada</option>
              <option value="O">Otra</option>
            </select>
            <button type="text" class="btn btn-secondary" v-on:click.prevent="eliminarDireccion"><i class="fa fa-eraser"></i> Borrar</button>
          </div>
          <div class="form-group">
            <label>Orden (*):</label>
            <input type="number" class="form-control" v-model="item.tcr_orden" required>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label>Hora inicio radicación:</label>
              <input type="time" class="form-control" v-model="rad_hini">
            </div>
            <div class="form-group col-md-6">
              <label>Hora fin radicación:</label>
              <input type="time" class="form-control" v-model="rad_hfin">
            </div>
          </div>
          <div class="form-group text-center">
            <input type="checkbox" class="form-check-input" id="tcr_tramitado" v-model="item.tcr_tramitado">
            <label for="tcr_tramitado">Mostrar tramitado por:</label>
          </div>
          <div class="form-group">
            <label>Mensaje personalizado para sello:</label>
            <input type="text" class="form-control" v-model="item.tcr_mensaje_sello">
          </div>
          <div class="form-group text-center">
            <input type="checkbox" class="form-check-input" id="tcr_no_vpr" v-model="item.tcr_no_vpr">
            <label for="tcr_no_vpr">Invalidar permisos para respuestas:</label>
          </div>
          <br />
          <div v-if="message">
            <p class="alert alert-warning" role="alert">{{ message }}</p>
          </div>
          <div class="row text-center">
            <div class="col-md-4">
              <button type="submit" class="btn btn-primary" v-on:click="setClose(true)">{{ buttontext }}</button>
            </div>
            <div class="col-md-4">
              <button type="text" class="btn btn-success" v-on:click="setClose(false)">Guardar</button>
            </div>
            <div class="col-md-4">
              <button type="text" class="btn btn-secondary" v-on:click.prevent="cancel()">Cancelar</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  //import toastr from 'toastr';
  import moment from 'moment';

  export default {
    data(){
      return{
        add: true, //Create a new document
        close: true, // Close the page and return to list
        title: '',
        item: {},
        tpocors: [],
        message: '',
        buttontext: '',
        rad_hini: null,
        rad_hfin: null
      }
    },
    computed: {
      company() {
        return this.$store.state.company
      },
      user() {
        return this.$store.state.user
      },
      cancelButtonVisibility() {
        if (this.addInd) {
          return 'visibility:hidden'
        } else {
          return 'visibility:visible'
        }
      }
    },
    created: function() {
      this.fetchTpocor();

      this.add = this.$route.params.add;
      if (this.add){
        this.title = 'Crear una Clase de documento';
        this.buttontext = 'Agregar Clase de documento';
      } else {
        this.title = 'Editar una Clase de documento';
        this.buttontext = 'Actualizar Clase de documento';
        this.getItem(this.$route.params.id);
      }
    },
    methods: {
      setClose(close){
        this.close = close;
      },
      saveItem(){
        if (this.rad_hini){
          var hi = new Date(this.rad_hini.substr(0,2)*60*60*1000 + this.rad_hini.substr(3,2)*60*1000);
          if(hi.getTimezoneOffset() > 0){
              hi.setTime( hi.getTime() + hi.getTimezoneOffset()*60*1000 );
          }
          this.item.tcr_rad_hini = hi;
        } else {
          this.item.tcr_rad_hini = undefined;
        }

        if (this.rad_hfin){
          var hf = new Date(this.rad_hfin.substr(0,2)*60*60*1000 + this.rad_hfin.substr(3,2)*60*1000);
          if(hf.getTimezoneOffset() > 0){
              hf.setTime( hf.getTime() + hf.getTimezoneOffset()*60*1000 );
          }
          this.item.tcr_rad_hfin = hf;
        } else {
          this.item.tcr_rad_hfin = undefined;
        }        

        if (this.add){
          this.item.cmp_id = String(this.$store.state.company);
          let uri = '/tpocors/add';
          //alert(this.item);
          this.axios.post(uri, this.item)
          .then(response => {
            console.log(response);
            this.$router.replace({ name: 'DisplayTpocor' });
            this.message = 'Registro guardado';
          })
          .catch(err => {
            console.log(err);
            this.message = '¡Error al crear el registro! ' + err;
          });
        }
        else {
          let uri = '/tpocors/update/' + this.item._id;
          this.axios.post(uri, this.item).then((response) => {
            if (this.close){
              this.$router.push({name: 'DisplayTpocor'});
            }
            this.message = 'Registro guardado';
          });
        }
      },
      getItem(id)
      {
        let uri = '/tpocors/edit/' + id;
        this.axios.get(uri).then((response) => {
          this.item = response.data;
          if (this.item.tcr_rad_hini){
            this.rad_hini = moment(this.item.tcr_rad_hini).format('HH:mm');
          }
          if (this.item.tcr_rad_hfin){
            this.rad_hfin = moment(this.item.tcr_rad_hfin).format('HH:mm');
          }
        });
      },
      fetchTpocor()
      {
        let uri = '/tpocors/' + String(this.$store.state.company);
        this.axios.get(uri).then((response) => {
          this.tpocors = response.data;
        });
      },
      cancel(){
        this.$router.replace({name: 'DisplayTpocor'});
      },
      eliminarTpocor(){
        this.item.tcr_respuesta = undefined;
      },
      eliminarDireccion(){
        this.item.tcr_direccion = undefined;
      }
    }
  }
  </script>

  <style>
    .warning {
      color: #ff0000;
    }
  </style>
